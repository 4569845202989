import { useCallback, useEffect, useState } from "react";
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import {
  MdAddCircle,
  MdSettings,
  MdInfo,
  MdMoreVert,
  MdAccountTree,
  MdCleaningServices,
  MdContentCopy,
  MdCheckCircle,
} from "react-icons/md";
import { useHistory } from "react-router-dom";
import { FlowDataI, FlowProps } from "../../@types/flow";
import Loading from "../../Components/Loading";
import Paginate from "../../Components/Paginate";
import Status from "../../Components/Status";
import dateFormatToBR from "../../utils/dateFormat";
import { allFluxos } from "../../services/flow";

import {
  Title,
  ContainerMain,
  CustomTable,
  CardHeader,
  DropdownCustom,
  DropdownItemCustom,
  ButtonLink,
  CustomButton,
  Box,
  CardIcon,
  NoFoundData,
  CardFooterCustom,
  BoxSearch,
  IconSearch,
  InputSearch,
  ButtonClearSearch,
  ButtonSearch,
  CustomForm,
  SelectFilter,
} from "./styles";
import CancelModal from "../../Components/CancelModal";
import { updateFluxo } from "../../services/flow";
import { useStateValue } from "../../providers/StateProvider";
import { actionTypes } from "../../store/reducer";
import { IoMdCloseCircle } from "react-icons/io";

const flowCurrentDataActive = {
  active: {
    description: "Deseja desativar esse fluxo?",
  },
  disabled: {
    description: "Deseja ativar esse fluxo?",
  },
};

const Flow = () => {
  const [, dispatch] = useStateValue();
  const [flowData, setFlowData] = useState<FlowProps>();
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState<string | undefined>();
  const [isSearch, setIsSearch] = useState(false);
  const history = useHistory();
  const [filterBy, setFilterBy] = useState("all");
  const [showModalCancel, setShowModalCancel] = useState<boolean>(false);
  const [flowDataSelected, setFlowDataSelected] = useState<FlowDataI | null>();
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [descriptionCancel, setDescriptionCancel] = useState<string | null>();
  const [currentFlowActive, setCurrentFlowActive] = useState<
    "error" | "success"
  >();

  const handleFluxos = async ({
    active,
    search,
    page,
  }: {
    search?: string;
    page?: number;
    active?: string;
  }) => {
    setLoading(true);
    setIsSearch(false);
    var isActive = undefined;

    try {
      if (page > 1) {
        setSearchData(search !== undefined ? search : searchData);
      }

      if (active) {
        isActive = true;
      }

      if (filterBy === "all") {
        isActive = undefined;
      }

      if (!filterBy) {
        isActive = false;
      }

      if (filterBy && filterBy !== "all") {
        isActive = true;
      }
      const { data } = await allFluxos(page, search, isActive);
      setFlowData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSuccess = (active: string) => {
    dispatch({
      type: actionTypes.SET_FLASH_MESSAGE,
      flashMessage: {
        show: true,
        type: "success-alt",
        title: "Sucesso",
        message: `Fluxo ${active} com sucesso!`,
      },
    });
  };

  const handleModalCancel = useCallback(
    () => setShowModalCancel((oldStateModal) => !oldStateModal),
    []
  );

  const handleEmptyData = () => {
    setFlowDataSelected(null);
    setDescriptionCancel(null);
  };

  const handleActiveItem = async (item: FlowDataI) => {
    try {
      if (item?.ativo) {
        setLoadingCancel(true);
        const {
          slug,
          titulo,
          descricao,
          servico_slug,
          orgao,
          modelo,
          automatico,
          terceiros,
          para,
          tipo,
          tipo_assinatura,
          tipo_validade,
          validade,
          integracao_externa,
          tipo_execucao,
          permitir_cancelamento_cidadao,
          permitir_retificacao,
          permitir_preenchimento_parcial,
          tempo_resposta_restituido,
          workflow_para_cancelamento,
        } = item;
        await updateFluxo(
          slug,
          titulo,
          descricao,
          servico_slug,
          false,
          orgao,
          modelo,
          automatico,
          terceiros,
          para,
          tipo,
          tipo_assinatura,
          tipo_validade,
          validade,
          integracao_externa,
          tipo_execucao,
          permitir_cancelamento_cidadao,
          permitir_retificacao,
          permitir_preenchimento_parcial,
          tempo_resposta_restituido,
          workflow_para_cancelamento
        );
        handleSuccess("desativado");
        handleFluxos({ page: 1 });
        handleEmptyData();
      } else {
        setLoadingCancel(true);
        const {
          slug,
          titulo,
          descricao,
          servico_slug,
          orgao,
          modelo,
          automatico,
          terceiros,
          para,
          tipo,
          tipo_assinatura,
          tipo_validade,
          validade,
          integracao_externa,
          tipo_execucao,
          permitir_cancelamento_cidadao,
          permitir_retificacao,
          permitir_preenchimento_parcial,
          tempo_resposta_restituido,
          workflow_para_cancelamento,
        } = item;
        await updateFluxo(
          slug,
          titulo,
          descricao,
          servico_slug,
          true,
          orgao,
          modelo,
          automatico,
          terceiros,
          para,
          tipo,
          tipo_assinatura,
          tipo_validade,
          validade,
          integracao_externa,
          tipo_execucao,
          permitir_cancelamento_cidadao,
          permitir_retificacao,
          permitir_preenchimento_parcial,
          tempo_resposta_restituido,
          workflow_para_cancelamento
        );
        handleSuccess("ativado");
        handleFluxos({ page: 1 });
        handleEmptyData();
      }
    } catch (error) {
      setLoadingCancel(false);
      if (error?.response?.data?.message) {
        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: "error-alt",
            title: "Error ao desativar",
            message: error?.response?.data?.message,
          },
        });
      }
      console.error(error?.response?.data?.message);
    } finally {
      setLoadingCancel(false);
    }
  };

  const handleResetInputSearch = () => {
    setFilterBy("all");
    setIsSearch(true);
    handleFluxos({ page: 1 });
    setSearchData("");
  };

  useEffect(() => {
    handleFluxos({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContainerMain>
        <Row>
          <Col md={12}>
            <Card>
              <CardHeader>
                <Box>
                  <CardIcon>
                    <MdAccountTree size={20} />
                  </CardIcon>
                  <Title>Fluxos</Title>
                </Box>
                <CustomButton
                  onClick={() => history.push("/fluxo/cadastrar-fluxo")}
                >
                  <MdAddCircle size={20} /> Novo fluxo
                </CustomButton>
              </CardHeader>
              <Card.Body>
                <CustomForm
                  onSubmit={(event) => {
                    event.preventDefault();
                    handleFluxos({
                      page: 1,
                      search: searchData.toLowerCase(),
                      active: filterBy === "all" ? undefined : filterBy,
                    });
                    setIsSearch(true);
                  }}
                >
                  <SelectFilter
                    onChange={(event) => {
                      setFilterBy(event.target.value);
                    }}
                  >
                    {(!isSearch ||
                      filterBy === "all" ||
                      filterBy === undefined) && (
                        <option value={"all"} selected={filterBy === "all"}>
                          {filterBy !== "all" ? "Todos" : "Filtrar por"}
                        </option>
                      )}
                    {(!isSearch || filterBy === "true") && (
                      <option value="true" selected={filterBy === "true"}>
                        Ativo
                      </option>
                    )}

                    {(!isSearch || filterBy === "false") && (
                      <option value="false" selected={filterBy === "false"}>
                        Inativo
                      </option>
                    )}
                  </SelectFilter>

                  <BoxSearch>
                    <IconSearch />
                    <InputSearch
                      type="text"
                      placeholder="Faça uma busca"
                      value={searchData}
                      onChange={(event) => setSearchData(event.target.value)}
                    />
                  </BoxSearch>
                  {!isSearch ? (
                    <ButtonSearch
                      className="mt-1"
                      type="submit"
                      disabled={loading}
                    >
                      Buscar
                    </ButtonSearch>
                  ) : (
                    <ButtonClearSearch
                      className="mt-1 text-light"
                      type="button"
                      variant="info"
                      onClick={handleResetInputSearch}
                    >
                      <MdCleaningServices
                        size={15}
                        style={{ marginRight: 4 }}
                      />{" "}
                      Limpar
                    </ButtonClearSearch>
                  )}
                </CustomForm>
                {loading ? (
                  <div className="d-flex mt-4 justify-content-center">
                    <Loading />
                  </div>
                ) : (
                  <>
                    {flowData && flowData.results.length > 0 ? (
                      <CustomTable responsive="md">
                        <thead className="table-header">
                          <tr>
                            <th>Título</th>
                            <th>Ativo</th>
                            <th>Modelo</th>
                            <th>Resposta Automática</th>
                            <th>Criado em</th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                        <tbody className="table-body">
                          {flowData.results.map((flow) => (
                            <tr key={flow.slug}>
                              <td>{flow.titulo}</td>
                              <td>
                                <Status type={flow.ativo.toString()} />
                              </td>
                              <td>
                                <Status type={flow.modelo.toString()} />
                              </td>
                              <td>
                                <Status type={flow.automatico.toString()} />
                              </td>
                              <td>{dateFormatToBR(flow.created_at, true)}</td>
                              <td>
                                <DropdownCustom>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="drop-button"
                                  >
                                    <MdMoreVert size={20} className="icon" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <DropdownItemCustom>
                                      <ButtonLink
                                        onClick={() =>
                                          history.push(
                                            `/fluxo/${flow.slug}/editar`
                                          )
                                        }
                                      >
                                        <MdSettings className="icon-action" />
                                        Gerenciar informações
                                      </ButtonLink>
                                    </DropdownItemCustom>
                                    <DropdownItemCustom>
                                      <ButtonLink
                                        onClick={() =>
                                          history.push(
                                            `/informacoes/${flow.slug}`
                                          )
                                        }
                                      >
                                        <MdInfo className="icon-action" />
                                        Gerenciar fluxo
                                      </ButtonLink>
                                    </DropdownItemCustom>
                                    {flow.modelo && (
                                      <DropdownItemCustom>
                                        <ButtonLink
                                          onClick={() =>
                                            history.push(
                                              `/fluxo/copiar-fluxo/${flow.slug}`
                                            )
                                          }
                                        >
                                          <MdContentCopy className="icon-action" />
                                          Copiar fluxo
                                        </ButtonLink>
                                      </DropdownItemCustom>
                                    )}
                                    {!flow.modelo && (
                                      <DropdownItemCustom>
                                        <ButtonLink
                                          onClick={() => {
                                            handleModalCancel();
                                            setFlowDataSelected(
                                              flow as FlowDataI
                                            );
                                            setDescriptionCancel(
                                              flow.ativo
                                                ? flowCurrentDataActive.active
                                                  .description
                                                : flowCurrentDataActive.disabled
                                                  .description
                                            );
                                            setCurrentFlowActive(
                                              flow.ativo ? "error" : "success"
                                            );
                                          }}
                                        >
                                          {flow.ativo ? (
                                            <>
                                              <IoMdCloseCircle className="icon-action" />
                                              Desativar
                                            </>
                                          ) : (
                                            <>
                                              <MdCheckCircle className="icon-action" />
                                              Ativar
                                            </>
                                          )}
                                        </ButtonLink>
                                      </DropdownItemCustom>
                                    )}
                                  </Dropdown.Menu>
                                </DropdownCustom>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </CustomTable>
                    ) : (
                      <NoFoundData>Sem resultados!</NoFoundData>
                    )}
                  </>
                )}
              </Card.Body>
              {flowData?.results?.length > 0 && (
                <CardFooterCustom>
                  <Paginate
                    flowData={flowData}
                    setPage={handleFluxos}
                    page={flowData?.current}
                  />
                </CardFooterCustom>
              )}
            </Card>
          </Col>
        </Row>
      </ContainerMain>
      {descriptionCancel && showModalCancel && (
        <CancelModal
          handleShow={handleModalCancel}
          show={showModalCancel}
          description={descriptionCancel}
          title="Fluxos"
          handleActionOk={() => handleActiveItem(flowDataSelected)}
          loading={loadingCancel}
          type={currentFlowActive}
        />
      )}
    </>
  );
};

export default Flow;
