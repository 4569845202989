import { AxiosResponse } from "axios";
import { apisiseci, apisso } from "./api";
import { UserData, UserDataSecondary } from "../interfaces/user";
import getCookie from "../utils/getCookies";

// função auxiliar para setar cookies
export const setCookie = (
  cname: string,
  cvalue: string,
  exdays: number
): void => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const saveTokens = (tokenSISECI: string): void => {
  setCookie("gov_access_token", tokenSISECI, 1);
};

export const clearStorage = (): void => {
  document.cookie.split(";").forEach((c) => {
    const [key, ...rest] = c.split("=");
    console.log(`Attempting to delete cookie: ${key}`);
    document.cookie = `${key.trim()}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    console.log(
      `Cookie ${key} now: `,
      document.cookie.split(";").find((cookie) => cookie.includes(key))
    );
  });
};

export const getPathToRedirectLogin = (): string => {
  if (process.env.REACT_APP_SSO_LOGIN_PROVIDER === "primary") {
    return `${process.env.REACT_APP_SSO}/sso?client_id=${process.env.REACT_APP_CLIENT_ID}`;
  }

  return `${process.env.REACT_APP_SSO_SECUNDARY}/auth?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=${process.env.REACT_APP_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;
};

export const loginRedirectPrivider = (pathname: string): void => {
  clearStorage();
  setCookie("pathname_redirect_gov", pathname, 1);
  (window as any).open(getPathToRedirectLogin(), "_self");
};

export const logout = async () => {
  if (process.env.REACT_APP_SSO_LOGIN_PROVIDER === "primary") {
    clearStorage();
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (window as any).open(
      `${process.env.REACT_APP_SSO}/sso/sair?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${window.location.href}`,
      "_self"
    );
  } else {
    clearStorage();
    (window as any).open(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${process.env.REACT_APP_SSO_SECUNDARY}/logout?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=${process.env.REACT_APP_RESPONSE_TYPE}&redirect_uri=${window.location.href}`,
      "_self"
    );
  }
};

export const getDataUserSso = (token: string): Promise<AxiosResponse> => {
  return apisso.get("/cidadaos/pro/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getDataUserSecondary = (
  token: string,
  access_token_secondary: string
): Promise<AxiosResponse> =>
  apisiseci.get("/profile/externo", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Authorization-Externo": access_token_secondary,
    },
  });

export const validateUserSSO = (code: string): Promise<AxiosResponse> =>
  apisso.post(
    "/validar_cidadao/",
    {
      code,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const loginSigp = (
  code: string,
  redirect_uri?: string
): Promise<AxiosResponse> => {
  if (process.env.REACT_APP_SSO_LOGIN_PROVIDER === "primary") {
    return apisso.post("/validar/", {
      code,
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_SSO_CLIENT_SECRET,
      redirect_uri: `${process.env.REACT_APP_URL}/login/callback/`,
    });
  }
  return apisiseci.post(
    "/login/externo/",
    {
      code,
      redirect_uri: redirect_uri || process.env.REACT_APP_REDIRECT_URI,
    },
    {
      headers: {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        Authorization: process.env.REACT_APP_API_SISECI_KEY,
      },
    }
  );
};

const secondaryDataUserAdapter = (data: UserDataSecondary): UserData => {
  const dataF = {
    nome: data.name || "",
    data_nascimento: data.dataNascimento || "",
    nome_mae: data.nomeMae || "",
    cpf: data.preferred_username || data.username || data.cpf || "",
    contato: {
      email: data.email || "",
    },
  };
  return dataF as UserData;
};

export const getUserData = async (
  token: string,
  access_token_secondary?: string
) => {
  if (process.env.REACT_APP_SSO_LOGIN_PROVIDER === "primary") {
    console.log(token, "token");
    const { data } = await getDataUserSso(token);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data[0];
  }

  const { data } = await getDataUserSecondary(access_token_secondary, token);
  return secondaryDataUserAdapter(data);
};

export const sendCodeForSISECI = async (code: string, history: any) => {
  const pathname = getCookie("pathname_redirect_gov");

  try {
    const { data } = await loginSigp(
      code,
      `${window.location.origin}${window.location.pathname}`
    );
    console.log(data);

    if (data.access_token) {
      saveTokens(data.access_token);
      const spathname = pathname === "/" ? "/home" : pathname;
      history.push(spathname || "/home", {
        message: data.message ? data.message : "",
      });
    }
  } catch (err) {
    clearStorage();
    history.push(pathname || "/", {
      message: "Erro ao processar o login",
    });
  }
};

export const getImageProfile = async (token: string) => {
  return apisso.get("cidadaos/foto/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
